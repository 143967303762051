<template>
    <b-sidebar
        v-if="isTablet || navOpened"
        class="nav"
        position="fixed"
        :fullheight="true"
        :fullwidth="isFullwidth"
        :overlay="false"
        :open="true"
        :can-cancel="['outside']"
        :reduce="isReduced"
    >
        <vue-scroll ref="vs">
            <div class="nav__inner" ref="navInner">
                <div class="nav__top">
                    <div class="nav__toolbar">
                        <router-link to="/">
                            <be-logo
                                :display-mini="isReduced"
                                class="nav__toolbar__logo"
                            />
                        </router-link>
                        <button v-if="isMobile" type="button" class="nav__toolbar__close" @click="toggleNav()">
                            <b-icon icon="close" />
                        </button>
                    </div>
                </div>
                <div class="nav__menus">
                    <b-menu
                        class="nav__menu"
                        v-for="(section, sectionIndex) in menu"
                        :key="`section${sectionIndex}`"
                    >
                        <b-menu-list class="nav__menu__list">
                            <template v-if="section.heading">
                                <slot name="label">
                                    <li class="nav__menu__heading">
                                        <b-icon v-if="isReduced" icon="dots-horizontal" />
                                        <span v-else class="text">{{ section.heading }}</span>
                                    </li>
                                </slot>
                            </template>
                            <b-menu-item
                                v-for="(item, itemIndex) in section.items"
                                :key="`sectionItem${sectionIndex}-${itemIndex}`"
                                tag="router-link"
                                :to="item.to"
                                class="nav__menu__item"
                                :class="{ 'has-sub-active': item.hasSub }"
                                @click.native="maybeCloseNav()"
                            >
                                <template v-slot:label>
                                    <template v-if="!isReduced">
                                        <b-icon :icon="item.icon" />
                                        <span v-text="item.label" />
                                    </template>
                                    <template v-else>
                                        <b-icon :icon="item.icon" />
                                    </template>
                                </template>
                            </b-menu-item>
                        </b-menu-list>
                    </b-menu>
                    <div style="margin-left: 15px;margin-bottom: 20px;">
                        <template v-if="!isReduced">
                            <span>RVision {{ version }}</span>
                        </template>
                        <template v-else>
                            <span text="" />
                        </template>
                    </div>
                    <div class="nav__collapse" v-if="isTablet">
                        <button
                            type="button"
                            class="nav__collapse__cta"
                            @click="toggleNav()"
                        >
                            <b-icon :icon="navOpened ? 'chevron-left' : 'chevron-right'" />
                            <span v-if="navOpened" class="text">
                                Collapse
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </vue-scroll>
    </b-sidebar>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import BeLogo from '@/components/global/BeLogo';

const pkg = require('@/../package.json');

const { version } = pkg;

export default {
    components: {
        BeLogo,
    },

    props: {
        slug: {
            type: String,
            default: 'dashboardMenu',
        },
    },

    computed: {
        ...mapState('sites', ['sites', 'sitesCount', 'sitesPerPage', 'sitesCurrentPage']),
        ...mapState('dashboard/layout', ['navOpened']),
        ...mapGetters('marketplace/user', ['allEnabledApps']),
        ...mapGetters('nav', ['menu']),

        version() {
            return version;
        },

        isTablet() {
            return this.$bus.isTablet;
        },

        isMobile() {
            return this.$bus.isMobile;
        },

        isFullwidth() {
            return this.isMobile;
        },

        isReduced() {
            return this.isTablet && !this.navOpened;
        },
    },

    mounted() {
        this.$store.dispatch('user/updateAppOptions');
        this.getSites({});
    },

    watch: {
        sites(val) {
            if (val) {
                const apps = this.allEnabledApps;
                if (this.sites[0].site_type === 0 && !apps.includes('parking-management')) {
                    apps.push('parking-management');
                }
            }
        },

        isMobile: {
            handler(val) {
                if (val) {
                    this.toggleNav(false);
                }
            },
            immediate: true,
        },

        allEnabledApps: {
            handler(val) {
                if (val) {
                    this.$store.dispatch('user/updateAppOptions');
                }
            },
            deep: true,
        },

        navOpened(val) {
            this.$nextTick(() => {
                if (val && this.isMobile) {
                    disableBodyScroll(this.$refs.vs.$el);
                } else {
                    clearAllBodyScrollLocks();
                }
            });
        },
    },

    methods: {
        ...mapMutations('dashboard/layout', ['toggleNav']),
        ...mapActions('sites', ['getSites']),

        maybeCloseNav() {
            if (this.isMobile && this.navOpened) {
                this.toggleNav(false);
            }
        },
    },
};
</script>

<style lang="scss" scoped>

@mixin menu-link-active {
    background: $black;
    &:hover {
        opacity: 1;
        background: $black;
    }
    .icon {
        color: $primary;
    }
}

.nav-bg {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    @include z-index(bg);
}

.nav {
    @include z-index(nav);

    ::v-deep .sidebar-content {
        border-right: $app-border;
    }

    &__inner {
        background: $grey-darker;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        font-size: 1rem;
        letter-spacing: 0.01em;
    }

    &__top {
        /*flex: 1;*/
    }

    &__toolbar {
        background: $black-bis;
        border-bottom: $app-border;
        padding: 0 20px 0 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include be-toolbar-height;
        margin-bottom: 30px;

        @include mobile {
            padding-left: 24px;
        }

        @include tablet {
            margin-bottom: 30px;
        }
        @include desktop {
            margin-bottom: 40px;
        }

        .is-mini & {
            padding: 0;
            justify-content: center;
        }

        &__logo {
            height: 31px;
        }

        &__close {
            @include button-reset;
            width: 30px;
            height: 30px;
            color: $white;
            font-size: 16px;
        }
    }

    &__menus {
        @include mobile {
            padding-left: 15px;
            padding-right: 15px;
        }
        padding-left: 40px;
        padding-right: 40px;

        .is-mini & {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &__menu {
        padding-bottom: 20px;
        margin-bottom: 30px;
        border-bottom: 1px solid $grey;

        &__item ::v-deep a {
            color: $white;
            display: flex;
            height: 48px;
            align-items: center;
            border-radius: $radius;
            transition: background-color $speed $easing, opacity $speed $easing;

            &:hover {
                color: $white;
                background: transparent;
                opacity: .7;
            }

            .icon {
                color: $grey-light;
                margin-right: 10px;
            }

            &.router-link-exact-active {
                @include menu-link-active;
            }

            .is-mini & {
                padding-left: 0;
                padding-right: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    margin-right: 0;
                }
            }
        }

        &__item.has-sub-active ::v-deep a {
            &.router-link-active {
                @include menu-link-active;
            }
        }

        &__heading {
            color: $white;
            display: flex;
            align-items: center;
            margin-bottom: 1rem;
            padding: 0 0.75em;
            line-height: 22px;

            .is-mini & {
                padding-left: 0;
                padding-right: 0;
                justify-content: center;
            }

            .text {
                font-size: 16px;
                font-weight: $weight-bold;
                @include tablet {
                    font-size: 18px;
                }
            }
        }
    }

    &__collapse {
        padding-bottom: 30px;

        &__cta {
            display: inline-flex;
            color: $white;
            height: 30px;
            line-height: 30px;
            background: transparent;
            padding: 0;
            border: 0;
            margin: 0;
            outline: none;
            cursor: pointer;
            transition: opacity .2s $easing;
            align-items: center;
            justify-content: center;
            font-family: $family-primary;
            font-size: 1rem;
            letter-spacing: 0.01em;

            &:hover {
                opacity: .7;
            }

            .icon {
                display: block;
                width: 30px;
                height: 30px;
                background: rgba($white, .3);
                border-radius: $radius-rounded;
                margin-left: .75rem;

                .is-mini & {
                    margin-left: 5px;
                }
            }

            .text {
                display: block;
                margin-left: 10px;
            }
        }
    }
}
</style>
